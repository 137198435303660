import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n  .hydroboots .faq__item__name { color: #77787b; }\n  .hydroboots .faq__item__content { color:#77787b; }\n  .hydroboots .faq__item__short .btn__more { background-color: #cfddf2; }\n  .hydroboots .faq__item__short .btn__less { background-color: #cfddf2; }\n"
          }}
        />
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="section">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Pielęgnacja twarzy</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/bad-piekna/">Bądź piękna</a> <span>&gt;</span>
                  <a href="/site/bad-piekna/pielegnacja-twarzy/">
                    Pielęgnacja twarzy
                  </a>{" "}
                  <span>&gt;</span>
                  Pielęgnacja twarzy
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/site/bad-piekna/test-skory/" className="link">
                Test skóry
              </a>
              <a
                href="/site/bad-piekna/ogolnopolskie-badanie-skory/"
                className="link"
              >
                Badanie Skóry
              </a>
              <a href="/site/bad-piekna/sucha-skora/" className="link">
                Sucha skóra
              </a>
              <a href="/site/bad-piekna/pielegnacja-twarzy/" className="link">
                Pielęgnacja twarzy
              </a>
              <a href="/site/bad-piekna/pielegnacja-ust/" className="link">
                Pielęgnacja ust
              </a>
              <a href="/site/bad-piekna/pielegnacja-stop/" className="link">
                Pielęgnacja stóp
              </a>
              <a href="/site/bad-piekna/pielegnacja-ciala/" className="link">
                Pielęgnacja ciała i regeneracja skóry
              </a>
              <a href="/site/bad-piekna/pielegnacja-doni/" className="link">
                Pielęgnacja dłoni
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="text full">
                  <div className="p">
                    <div
                      className="column left"
                      style={{
                        width: "520px"
                      }}
                    >
                      <h2>Stwórz swój rytuał nawadniania skóry</h2>
                      <p>
                        Skóra wraz z wiekiem częściowo traci swoje mechanizmy
                        regeneracyjne. To sprawia, że staje się mniej
                        elastyczna, wiotczeje, a na jej powierzchni z czasem
                        pojawiają się zmarszczki. Dzieje się tak, ponieważ
                        tkanki tracą swoją gęstość, a połączenia między
                        komórkami tkanki tłuszczowej zanikają. Jest to wynikiem
                        upływającego czasu, który razem z czynnikami
                        środowiskowymi sprawia, że produkcja kolagenu i elastyny
                        maleje, a ich włókna stają się kruche, tracąc część
                        swoich pierwotnych właściwości.
                      </p>
                    </div>
                    <div
                      className="column right"
                      style={{
                        "-webkit-text-align": "center",
                        "text-align": "center"
                      }}
                    >
                      <img
                        className="left"
                        title
                        src="/site/assets/Uploads/author-2.png"
                        alt="author 2"
                        width="249"
                        height="250"
                      />
                      <br />
                      <br />
                      <p
                        style={{
                          "font-weight": "bold",
                          "font-style": "italic"
                        }}
                      >
                        dr Dominika Kwaśniak <br />
                        Lekarz Medycyny Estetycznej&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section
                style={{
                  position: "relative",
                  overflow: "visible"
                }}
              >
                <img
                  src="/site/themes/simple//img/produkty-seria.png"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "-200px"
                  }}
                />
                <div
                  className="text right"
                  style={{
                    "min-height": "410px",
                    position: "relative",
                    width: "470px"
                  }}
                >
                  <h2>
                    Dlaczego nawodnienie <br />
                    to podstawa pielęgnacji?
                  </h2>
                  <p>
                    Na upływający czas nie mamy wpływu, jednak dzięki
                    odpowiedniej pielęgnacji możemy znacząco poprawić kondycję
                    naszej skóry i zapobiegać jej przedwczesnemu starzeniu.
                    Niewłaściwy poziom wody w skórze oraz niedostateczne
                    natłuszczenie sprawiają, że równowaga wodno-lipidowa zostaje
                    zaburzona, a skóra staje się szorstka, szara i zmęczona.
                    Odpowiednie zabiegi pielęgnacyjne wykonywane regularnie i za
                    pomocą właściwych produktów nawilżających pozwolą nam
                    utrzymać młodzieńczy wygląd na długie lata.
                  </p>
                </div>
                <div
                  style={{
                    clear: "both"
                  }}
                />
              </section>
              <section className="last">
                <div className="text full">
                  <div className="p">
                    <h2>Jak dobrać odpowiedni produkt?</h2>
                    <p>
                      Produkty o właściwościach nawadniających zawsze należy
                      dobierać pod kątem wieku, płci, rodzaju skóry oraz jej
                      problemów. Możemy je stosować już poniżej 20. roku życia.
                      Zaczynając odpowiednią pielęgnację nawilżającą tak
                      wcześnie, zwiększamy swoje szanse na utrzymanie zdrowo
                      wyglądającej skóry przez długi czas.
                      <br /> Warto zwrócić uwagę, aby kosmetyki nawadniające
                      były bogate w składniki aktywne, tj. kwas hialuronowy i
                      glicerynę, które wykazują doskonałe właściwości
                      utrzymywania odpowiedniej ilości wody w skórze. Jedna
                      cząsteczka kwasu hialuronowego wiąże aż 250 cząsteczek
                      wody. Gliceryna użyta w odpowiednich ilościach pomaga zaś
                      utrzymać właściwy poziom natłuszczenia wysuszonej skóry,
                      co ma szczególnie znaczenie dla osób borykających się z
                      przesuszoną, łuszczącą się cerą. <br />
                      <br />
                      <br />
                    </p>
                    <h2>Jak stworzyć rytuał nawadniający?</h2>
                    <p>
                      Pamiętajmy, że systematyczność w stosowaniu kosmetyków
                      jest szczególnie ważna, aby przekonać się o rezultatach
                      używanych produktów. Żyjemy szybko i nie mamy czasu na
                      wielogodzinne zabiegi pielęgnacyjne. Nasze kosmetyki muszą
                      więc być do tego dopasowane. Idealne preparaty będą
                      uwalniać substancje nawadniające jeszcze wiele godzin po
                      nałożeniu – wtedy, kiedy skóra będzie tego potrzebowała.
                      <br /> Oto kilka kroków w pielęgnacji, o których
                      powinniśmy pamiętać: <br />
                      <br />
                      <br />
                    </p>
                    <div className="column left">
                      <ul>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Oczyszczanie – etap I
                          </p>
                          Każde oczyszczanie twarzy należy rozpocząć od
                          dokładnego zmycia makijażu, zanieczyszczeń oraz
                          nadmiaru sebum. Produktem odpowiednim do tego zabiegu
                          jest np. mleczko, które skutecznie czyści, nie
                          powodując przy tym uszkodzenia bariery wodno-lipidowej
                          skóry.
                          <br />
                          <br />
                        </li>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Oczyszczanie – etap II
                          </p>
                          Kolejnym etapem oczyszczania jest użycie żelu
                          myjącego. Dzięki zdolności do tworzenia piany
                          doskonale usuwa on zanieczyszczenia, bakterie,
                          zgromadzony nadmiar sebum oraz resztki makijażu.
                          Wybierajmy żele wzbogacone o dodatkowe składniki, np.
                          kwas hialuronowy.
                          <br />
                          <br />
                        </li>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Złuszczanie
                          </p>
                          Aby zapewnić jeszcze większą skuteczność produktów
                          nawilżających, należy raz na dwa tygodnie zastosować
                          peeling, by złuszczyć martwe komórki naskórka oraz
                          odblokować pory. Proces ten poprawia ukrwienie skóry,
                          dzięki czemu składniki preparatów użytych w kolejnych
                          etapach rytuału nawadniania lepiej się wchłaniają.
                          <br />
                          <br />
                        </li>
                      </ul>
                    </div>
                    <div className="column right">
                      <ul>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Nawilżanie
                          </p>
                          To moment na zastosowanie odpowiedniego kremu lub też,
                          w zależności od preferencji, kosmetyku o konsystencji
                          żelowej. Powinno się go nakładać minimum dwa razy
                          dziennie, a jeśli nasza skóra jest bardzo odwodniona,
                          nawet częściej. Wybierając produkt, należy się
                          kierować zarówno wiekiem, jak i rodzajem cery.
                          Pamiętajmy także o pielęgnacji delikatnej skóry w
                          okolicach oczu.
                          <br />
                          <br />
                        </li>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Maska
                          </p>
                          Jeśli zależy nam na efekcie długotrwałego nawilżenia,
                          warto zastosować maskę, która zapewni skórze
                          skoncentrowaną dawkę składników odżywczych w krótkim
                          czasie. Pamiętajmy, że skóra w trakcie snu wykazuje
                          bardzo silne zdolności regeneracyjne.
                          <br />
                          <br />
                        </li>
                        <li>
                          <p
                            style={{
                              color: "#fe0000",
                              padding: "0 0 5px"
                            }}
                          >
                            Pielęgnacja ciała
                          </p>
                          Wprowadzając w życie rytuał nawadniania, nie możemy
                          zapominać o ciele, które podobnie jak twarz potrzebuje
                          nawilżenia. Warto pamiętać, że przy wyborze tego typu
                          produktów również należy się kierować zawartością
                          składników nawilżających.
                        </li>
                      </ul>
                    </div>
                    <div
                      style={{
                        clear: "both"
                      }}
                    >
                      &nbsp;
                    </div>
                    <div
                      style={{
                        background: "#e8eff9",
                        margin: "50px 0 0",
                        padding: "50px"
                      }}
                    >
                      <h2>Rada naszego Eksperta</h2>
                      Podczas planowania swojego rytuału pielęgnacji twarzy i
                      ciała, warto zdecydować się na kosmetyki z jednej linii.
                      Dzięki temu unikniemy kolizji składników zawartych w
                      produktach różnych producentów i związanych z nimi
                      ewentualnych reakcji alergicznych skóry.
                    </div>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="text full">
                  <div
                    className="p"
                    style={{
                      "-webkit-text-align": "center",
                      "text-align": "center"
                    }}
                  >
                    <h2>Pytania i odpowiedzi</h2>
                    Czego jeszcze nie wiesz <br />o kwasie hialuronowym?
                  </div>
                  <div
                    className="hydroboots"
                    style={{
                      margin: "50px 0 0"
                    }}
                  >
                    <div className="faq">
                      <div className="faq__item">
                        <div className="faq__item__photo">
                          <img
                            title
                            src="/site/assets/Uploads/faq-1.png"
                            alt="Czy efekt zastosowania kremu z kwasem hialuronowym widoczny jest od razu po użyciu?"
                          />
                        </div>
                        <div className="faq__item__content">
                          <h3 className="faq__item__name">
                            Czy efekt zastosowania kremu z kwasem hialuronowym
                            widoczny jest od razu po użyciu?
                          </h3>
                          <div className="faq__item__short">
                            <span className="btn__more">&nbsp;</span>Kwas
                            hialuronowy wchłania wodę, dlatego efekt działania
                            kremu nie będzie widoczny natychmiast, potrzebny
                            jest na to czas. Szczyt nawilżenia...
                          </div>
                          <div className="faq__item__full">
                            Kwas hialuronowy wchłania wodę, dlatego efekt
                            działania kremu nie będzie widoczny natychmiast,
                            potrzebny jest na to czas. Szczyt nawilżenia skóry
                            odczujemy po mniej więcej miesiącu regularnego
                            stosowania kosmetyków.
                            <span className="btn__less">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                      <div className="faq__item">
                        <div className="faq__item__photo">
                          <img
                            title
                            src="/site/assets/Uploads/faq-3.png"
                            alt="Czy w każdym wieku można stosować krem z kwasem hialuronowym?"
                          />
                        </div>
                        <div className="faq__item__content">
                          <h3 className="faq__item__name">
                            Czy w każdym wieku można stosować krem z kwasem
                            hialuronowym?
                          </h3>
                          <div className="faq__item__short">
                            <span className="btn__more">&nbsp;</span>O
                            nawilżenie skóry należy dbać od najmłodszych lat.
                            Kremu z kwasem hialuronowym mogą używać już
                            20-latki. Dzięki niemu cera staje się promienna...
                          </div>
                          <div className="faq__item__full">
                            O nawilżenie skóry należy dbać od najmłodszych lat.
                            Kremu z kwasem hialuronowym mogą używać już
                            20-latki. Dzięki niemu cera staje się promienna,
                            gładsza, bardziej jędrna oraz zyskuje barierę
                            ochronną przed wysuszeniem i czynnikami
                            zewnętrznymi.
                            <span className="btn__less">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                      <div className="faq__item">
                        <div className="faq__item__photo">
                          <img
                            title
                            src="/site/assets/Uploads/faq-4.png"
                            alt="Czy kwas hialuronowy działa profilaktycznie na skórę, chroniąc ją przed powstawaniem zmarszczek?"
                          />
                        </div>
                        <div className="faq__item__content">
                          <h3 className="faq__item__name">
                            Czy kwas hialuronowy działa profilaktycznie na
                            skórę, chroniąc ją przed powstawaniem zmarszczek?
                          </h3>
                          <div className="faq__item__short">
                            <span className="btn__more">&nbsp;</span>Kwas
                            hialuronowy dzięki swoim właściwościom wiązania
                            ogromnej ilości wody oraz zatrzymywania jej w
                            naskórku...
                          </div>
                          <div className="faq__item__full">
                            Kwas hialuronowy dzięki swoim właściwościom wiązania
                            ogromnej ilości wody oraz zatrzymywania jej w
                            naskórku działa jak gąbka, utrzymując prawidłowe
                            nawilżenie skóry. W konsekwencji poprawia się jej
                            gęstość, co zabezpiecza skórę przed tworzeniem się
                            zmarszczek.<span className="btn__less">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                      <div className="faq__item">
                        <div className="faq__item__photo">
                          <img
                            title
                            src="/site/assets/Uploads/faq-5.png"
                            alt="Czy kwas hialuronowy jest bezpieczny dla oczu?"
                          />
                        </div>
                        <div className="faq__item__content">
                          <h3 className="faq__item__name">
                            Czy kwas hialuronowy jest bezpieczny dla oczu?
                          </h3>
                          <div className="faq__item__short">
                            <span className="btn__more">&nbsp;</span>Nie należy
                            się obawiać stosowania kremów z kwasem hialuronowym
                            w okolicy...
                          </div>
                          <div className="faq__item__full">
                            Nie należy się obawiać stosowania kremów z kwasem
                            hialuronowym w okolicy oczu, gdyż gałka oczna w
                            swoim składzie zawiera nic innego jak kwas
                            hialuronowy.
                            <span className="btn__less">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                      <div className="faq__item">
                        <div className="faq__item__photo">
                          <img
                            title
                            src="/site/assets/Uploads/faq-6.png"
                            alt="Czy na skórę dojrzałą należy stosować produkty przeciwzmarszczkowe, czy odpowiednie będą te z kwasem hialuronowym?"
                          />
                        </div>
                        <div className="faq__item__content">
                          <h3 className="faq__item__name">
                            Czy na skórę dojrzałą należy stosować produkty
                            przeciwzmarszczkowe, czy odpowiednie będą te z
                            kwasem hialuronowym?
                          </h3>
                          <div className="faq__item__short">
                            <span className="btn__more">&nbsp;</span>Skóra
                            dojrzała wymaga bardziej zaawansowanej pielęgnacji
                            ze składnikami regenerującymi...
                          </div>
                          <div className="faq__item__full">
                            Skóra dojrzała wymaga bardziej zaawansowanej
                            pielęgnacji ze składnikami regenerującymi, ale nie
                            powinno się zapominać także o jej odpowiednim
                            nawilżeniu kwasem hialuronowym, gdyż stanowi on
                            podłoże dla włókien kolagenu i elastyny, które
                            napinają naszą skórę.
                            <span className="btn__less">&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div id="social_id_4"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z
                o. o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 25/10/2013
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
